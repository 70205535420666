import React, { Component } from "react";

class Music extends Component {
  state = {
    musicIFrames: [
      '<iframe style="border: 0; width: 250px; height: 420px;" src="https://bandcamp.com/EmbeddedPlayer/album=1328967607/size=large/bgcol=333333/linkcol=ffffff/tracklist=false/transparent=true/" seamless><a href="https://stuetzpunkt.bandcamp.com/album/chord141-ep-sp002">Chord141 EP [SP002] by Staim</a></iframe>',
      '<iframe style="border: 0; width: 250px; height: 420px;" src="https://bandcamp.com/EmbeddedPlayer/album=2872952113/size=large/bgcol=333333/linkcol=ffffff/tracklist=false/transparent=true/" seamless><a href="https://stuetzpunkt.bandcamp.com/album/aleph-sp001">Aleph [SP001] by Stützpunkt</a></iframe>',
      '<iframe style="border: 0; width: 250px; height: 420px;" src="https://bandcamp.com/EmbeddedPlayer/track=2136498640/size=large/bgcol=333333/linkcol=ffffff/tracklist=false/transparent=true/" seamless><a href="https://stuetzpunkt.bandcamp.com/track/mono-evolver-2">Mono Evolver by Saturator</a></iframe>',
    ],
  };
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row justify-content-center dashed m-2 mt-3">
            {/* <h1 className="music-banner p-4">Releases</h1> */}
            {this.state.musicIFrames.map((item) => (
              <div className="col music-list-item" key={item}>
                <div
                  className="music-item"
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Music;
