import React, { Component } from "react";

class Event extends Component {
  state = {
    isActive: true,
  };
  render() {
    return (
      <div className="event-container" onClick={() => this.handleClick()} style={{cursor: "pointer"}}>
        <img
          src={require(`../../assets/images/${this.props.event.id}.webp`)}
          className="event-image"
          // Lazy loading and image optimization (async decoding)
          style={{
            opacity: this.state.isActive | (window.innerWidth <= 480) ? 1 : 0.1,
            // Media query for mobile
          }}
          loading="lazy"
          decoding="async"
        ></img>
        <div
          className="event-info"
          style={{
            padding: this.state.isActive ? "0px" : "1em",
            height: this.state.isActive ? 0 : "100%",
            opacity: this.state.isActive ? 0 : 1,
            visibility: this.state.isActive ? "hidden" : "visible",
          }}
        >
          <h3 className="artist-name">{this.props.event.name}</h3>
          <h4>{this.props.event.location} - {this.props.event.date}</h4>
          <p>
            {this.props.event.artists.map((artist, index) => (
              <span key={index}>
                {artist}
                {index !== this.props.event.artists.length - 1 && " - "}
              </span>
            ))}
          </p>
          <a
            href={this.props.event.link}
            className="soundcloud"
            target="_blank"
          >
            event link
          </a>
        </div>
      </div>
    );
  }
  handleClick = () => {
    // Change opacity of image to 0.5
    this.setState({ isActive: !this.state.isActive });
  };
}

// class Event extends Component {
//   render() {
//     return (
//       <div className="event-flex-container">
//         <div className="event-flex-row">
//           <div className="event-flex-col">
//             <div>
//               <img
//                 src={require(`../../assets/images/${this.props.event.id}.webp`)}
//                 className="img-fluid"
//                 style={{
//                   minHeight: "150px",
//                   minWidth: "150px",
//                 }}
//               />
//             </div>
//             <div>
//               <a href={this.props.event.link} className="soundcloud">
//                 event link
//               </a>
//             </div>
//           </div>
//           <div className="event-flex-col">
//             <h3 className="artist-name">{this.props.event.name}</h3>
//             <p>
//               {this.props.event.artists.map((artist, index) => (
//                 <span key={index}>
//                   {artist}
//                   {index !== this.props.event.artists.length - 1 && " - "}
//                 </span>
//               ))}
//             </p>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

export default Event;
