import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import $ from "jquery";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";

import { DotScreenShader } from "three/examples/jsm/shaders/DotScreenShader.js";
let camera, scene, renderer, composer, effect, renderpass;
let mouse_x = 0,
  mouse_y = 0,
  accelerometer_active = false,
  motionRequestSet = false;
let movedToTop = false;
let cam_dist = { dist: 1400 };

function moveSTZPKT() {
  if ("DeviceMotionEvent" in window) {
    if (!motionRequestSet) {
      if (typeof DeviceMotionEvent.requestPermission === "function") {
        DeviceMotionEvent.requestPermission()
          .then((response) => {
            motionRequestSet = true;
            if (response == "granted") {
              window.addEventListener("devicemotion", function (event) {
                if (
                  event.rotationRate.alpha ||
                  event.rotationRate.beta ||
                  event.rotationRate.gamma
                ) {
                  accelerometer_active = true;
                  mouse_y += event.rotationRate.alpha / 2000;
                  mouse_x -= event.rotationRate.beta / 2000;
                }
              });
            }
          })
          .catch(console.error);
      }
    }
  }

  // get element with id #c
  let c = document.getElementById("three");
  // change position of canvas
  c.style.transition = "all 1.5s ease-in-out";
  c.style.margin = "0";
  let band_top = document.getElementById("container-top");
  let band_bottom = document.getElementById("container-bot");
  let scroll = document.getElementById("scr-div");
  // let flx = document.getElementById("flx");
  // let header = document.getElementById("header");

  band_top.style.transition = "all 1s ease-in-out";
  band_top.style.transform = "translateX(-200%)";
  band_bottom.style.transition = "all 1s ease-in-out";
  band_bottom.style.transform = "translateX(200%)";
  scroll.style.transform = "translateY(1000px)";
  scroll.style.opacity = 0;
  scroll.style.transition = "all 1.5s ease-in-out";

  // flx.style.transition = "all 3s ease-in-out";
  // flx.style.pointerEvents = "all";
  // flx.style.opacity = 1;
  // header.style.transition = "all 3s ease-in-out";
  // header.style.opacity = 1;
  movedToTop = true;
  setTimeout(function () {
    scroll.style.display = "none";
    document.body.style.overflow = "visible";
  }, 2500);
}
// add event listener scrolling and click
$(window).on("mousewheel DOMMouseScroll click touchmove", moveSTZPKT);

init();
animate();

function resizeCanvasToDisplaySize() {
  const canvas = renderer.domElement;
  // look up the size the canvas is being displayed
  const width = canvas.clientWidth;
  // cam_dist.dist = width >= 600 ? 1400 : 600;
  const height = canvas.clientHeight;

  // adjust displayBuffer size to match
  if (canvas.width !== width || canvas.height !== height) {
    // you must pass false here or three.js sadly fights the browser
    renderer.setSize(width, height, false);
    // effect.setSize(width, height);

    effect.uniforms["tSize"].value = new THREE.Vector2(width, height);
    effect.uniforms["scale"].value = Math.min(2, Math.max(0.8, 800 / width));

    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    composer.setSize(width, height);
  }
  let dist_width = 500 / width;
  let dist_height = 150 / height;

  camera.position.z = Math.min(
    1200,
    Math.max(dist_height, dist_width) * cam_dist.dist
  );
}

function init() {
  camera = new THREE.PerspectiveCamera(20, 2, 1, 10000);
  // let aspect = window.innerWidth / window.innerHeight;
  // camera.position.z = 600 / Math.min(1, aspect);

  scene = new THREE.Scene();
  // scene background transparent
  // scene.background = new THREE.Color(0x000000);

  const loader = new GLTFLoader();
  loader.load(require("./stz.glb"), function (gltf) {
    // Decrease size of the model
    gltf.scene.scale.set(3000, 2000, 3000);
    // Rotate model 90 deg
    gltf.scene.rotation.x = Math.PI / 2;

    scene.add(gltf.scene);
  });

  // Ambient Light
  // const ambientLight = new THREE.AmbientLight(0xffffff, 0.05);
  // scene.add(ambientLight);

  const pointLight1 = new THREE.PointLight(0xffffff, 0.3);
  pointLight1.position.set(500, 500, 500);
  scene.add(pointLight1);

  const pointLight2 = new THREE.PointLight(0xffffff, 0.2);
  pointLight2.position.set(-500, 500, -500);
  scene.add(pointLight2);

  const pointLight3 = new THREE.PointLight(0xffffff, 0.3);
  pointLight3.position.set(-500, 500, 500);
  scene.add(pointLight3);

  const pointLight4 = new THREE.PointLight(0xffffff, 0.2);
  pointLight4.position.set(500, 500, -500);
  scene.add(pointLight4);

  // Renderer with anti-aliasing
  // const canvas = document.querySelector('#c');

  renderer = new THREE.WebGLRenderer({
    canvas: document.querySelector("canvas"),
    alpha: true,
    antialias: true,
  });
  renderer.clearColor = new THREE.Color(0, 0, 0);
  renderer.clearAlpha = 0;

  renderer.setPixelRatio(window.devicePixelRatio);
  // Set background alpha to zero
  renderer.setClearColor(0x000000, 0);

  composer = new EffectComposer(renderer);
  renderpass = new RenderPass(scene, camera);
  composer.addPass(renderpass);

  effect = new ShaderPass(DotScreenShader);
  composer.addPass(effect);
  const canvas = renderer.domElement;
  // look up the size the canvas is being displayed
  const width = canvas.clientWidth;
  const height = canvas.clientHeight;

  // composer.setSize(width, height);

  // renderer.setSize(window.innerWidth, window.innerHeight);
  // composer.setSize(window.innerWidth, window.innerHeight);

  // controls = new TrackballControls(camera, renderer.domElement);
  // // Disable translation
  // controls.noPan = true;
  // controls.minDistance = 800 / aspect;
  // // controls.maxDistance = 1750 / aspect;
  // controls.noRoll = true;

  // window.addEventListener('resize', onWindowResize);
  window.addEventListener("mousemove", function (event) {
    if (!accelerometer_active) {
      mouse_x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse_y = -(event.clientY / window.innerHeight) * 2 + 1;
    }
  });
  if ("DeviceMotionEvent" in window) {
    if (typeof DeviceMotionEvent.requestPermission !== "function") {
      window.addEventListener("devicemotion", function (event) {
        if (
          event.rotationRate.alpha ||
          event.rotationRate.beta ||
          event.rotationRate.gamma
        ) {
          accelerometer_active = true;
          mouse_y += event.rotationRate.alpha / 2000;
          mouse_x -= event.rotationRate.beta / 2000;
        }
      });
    }
  }
}

function animate() {
  requestAnimationFrame(animate);

  render();
}

function render() {
  // if (!movedToTop) {
  //   window.scrollTo(0, 0);
  // }
  // controls.update();
  // Rotate scene in accordance to mouse_x and mouse_y
  // const tween = new TWEEN.Tween(scene.rotation).to({ y: mouse_x / 3, x:-mouse_y / 3}, 20).easing(TWEEN.Easing.Quadratic.InOut).start();

  scene.rotation.y = mouse_x / 3;
  scene.rotation.x = -mouse_y / 3;
  // scene.rotation.y = (timer / 4000) % (Math.PI) - Math.PI/2;
  resizeCanvasToDisplaySize();
  composer.render(scene, camera);
}
