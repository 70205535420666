import React, { Component } from "react";
import Artist from "../components/Artist";

class Artists extends Component {
  state = {
    artists: [
      {
        name: "Alfandi",
        id: "alfandi",
        instagram: "https://www.instagram.com/alfandi_stuetzpunkt/",
        soundcloud: "https://soundcloud.com/background-radiation",
        residentAdvisor: "https://www.residentadvisor.net/dj/alfandi",
        youtubeEmbed: "https://www.youtube-nocookie.com/embed/Kq6Ky9wgYgc"
      },
      {
        name: "Background Radiation",
        id: "background-radiation",
        soundcloud: "https://soundcloud.com/background-radiation",
        residentAdvisor: "https://www.residentadvisor.net/dj/backgroundradiation",
        soundcloudEmbed: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/260348828&color=%23000000&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=false"
      },
      {
        name: "Bongsy",
        id: "bongsy",
        instagram: "https://www.instagram.com/asapbongsy/",
        soundcloud: "https://soundcloud.com/bongsy",
        residentAdvisor: "https://www.residentadvisor.net/dj/bongsy",
        soundcloudEmbed: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1052350255&color=%23000000&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=false"
      },
      {
        name: "Chami",
        id: "chami",
        instagram: "https://www.instagram.com/chami_stuetzpunkt/",
        soundcloud: "https://soundcloud.com/chamixx",
        residentAdvisor: "https://www.residentadvisor.net/dj/chami",
        soundcloudEmbed: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/993902662&color=%23000000&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=false"
      },
      {
        name: "OPH",
        id: "op-h",
        instagram: "https://www.instagram.com/ophsound/",
        soundcloud: "https://soundcloud.com/ophsound",
        residentAdvisor: "https://www.residentadvisor.net/dj/ophsound",
        soundcloudEmbed: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1216820101&color=%23000000&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=false"
      },
      {
        name: "Saturator",
        id: "saturator",
        instagram: "https://www.instagram.com/saturator_stuetzpunkt/",
        soundcloud: "https://soundcloud.com/saturatorberlin",
        residentAdvisor: "https://www.residentadvisor.net/dj/saturator-de",
      },
      {
        name: "Staim",
        id: "staim",
        instagram: "https://www.instagram.com/staim_/",
        soundcloud: "https://soundcloud.com/staimm",
        residentAdvisor: "https://www.residentadvisor.net/dj/staim",
        soundcloudEmbed: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/825024784&color=%23000000&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=false"
      },
      {
        name: "SVNT",
        id: "svnt",
        instagram: "https://www.instagram.com/mike_svnt/",
        soundcloud: "https://soundcloud.com/handsonsavant",
        soundcloudEmbed: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/692243824&color=%23000000&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=false"
      },
      {
        name: "Tymotica",
        id: "tymotica",
        instagram: "https://www.instagram.com/tymotica_/",
        soundcloud: "https://soundcloud.com/tymotica",
        residentAdvisor: "https://www.residentadvisor.net/dj/tymotica",
        soundcloudEmbed: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/781532620&color=%23000000&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=false"
      },
    ],
    activeArtist: "alfandi",
  };

  get currentArtist() {
    return this.state.artists.find(
      (artist) => artist.id === this.state.activeArtist
    );
  }

  render() {
    return (
      <div className="container">
        <a href="mailto:booking@stuetzpunkt-berlin.com">
        <h2 className="artist-name" style={{ margin: "1em 0 0 0", textAlign: "center" }}>
          Booking Request
        </h2>
        </a>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-5 mt-5">{this.getArtistList()}</div>
          <div className="col-sm-12 col-md-7 artist-info mt-5">
            <Artist artist={this.currentArtist} />
          </div>
        </div>
      </div>
    );
  }

  getArtistList() {
    return (
      <ul className="list-group">
        {this.state.artists.map((artist) => (
          <li
            className="list-group-item bg-transparent text-white hover-bold p-0"
            onClick={() => {
              this.setState({ activeArtist: artist.id });
            }}
            key={artist.id}
          >
            {artist.name}
          </li>
        ))}
      </ul>
    );
  }
}

export default Artists;
