import React, { Component } from "react";
import Event from "../components/Event";

class Events extends Component {
  state = {
    events: [
      {
        name: "Stützpunkt",
        date: "16.12.22",
        id: "16-12-22",
        artists: [
          "Anthony Tyrol",
          "Bad Boombox",
          "DHC",
          "DJ Sweet6teen",
          "Kim She",
          "The Chronics",
          "Trudge",
          "Zeynep"
        ],
        location: "OXI",
        link: "https://ra.co/events/1585492",
      },
      {
        name: "Stützpunkt",
        date: "24.11.22",
        id: "24-11-22",
        artists: [
          "DJ Sweet6teen",
          "ferrari rot",
          "morphena",
          "RUIZ OSC1",
          "Vermeer",
          "Versus",
        ],
        location: "://about blank",
        link: "https://ra.co/events/1549633",
      },

      {
        name: "Stützpunkt",
        date: "29.10.22",
        id: "29-10-22",
        artists: [
          "Alcatraz",
          "ALIS.",
          "Caiva",
          "Cryptofauna",
          "evin",
          "Lucia Lu",
          "Malugi",
          "OTON",
          "Southstar"
        ],
        location: "OXI",
        link: "https://ra.co/events/1585477",
      },

      {
        name: "Stützpunkt",
        date: "22.09.22",
        id: "22-09-22",
        artists: [
          "BAUGRUPPE90",
          "DJ AYA",
          "Formella"
        ],
        location: "://about blank",
        link: "https://ra.co/events/1549632",
      },
      {
        name: "Stützpunkt",
        date: "08.07.22",
        id: "08-07-22",
        artists: [
          "Alfandi",
          "Alienata",
          "DJ HEARTSTRING",
          "DJ Sweet6teen",
          "Narciss",
          "Olivia Mendez",
          "Patrick Mason",
          "Psyk",
          "Solid Blake",
          "Stella Zekri",
          "Thrived",
        ],
        location: "Anomalie Art Club",
        link: "https://ra.co/events/1541646",
      },
      {
        name: "Stützpunkt",
        date: "27.05.22",
        id: "27-05-22",
        artists: [
          "Alfandi",
          "Ferrari Rot",
          "Lorenzo Lacchesi",
          "Malugi",
          "Marsch",
          "Staím",
          "Surf 2 Glory",
        ],
        location: "://about blank",
        link: "https://ra.co/events/1531707",
      },
      {
        name: "Disconnekt x Stützpunkt x Sektgarten",
        date: "10.07.21",
        id: "10-07-21",
        location: "://about blank",
        artists: [
          "Alfandi",
          "Bongsy",
          "Elad Magdasi",
          "Hannah Addams",
          "Reka",
          "Zeynep",
        ],
        link: "https://ra.co/events/1449229",
      },
      {
        name: "Stützpunkt",
        date: "31.01.20",
        id: "31-01-20",
        location: "Suicide Club",
        artists: ["Boddika", "JoeFarr", "OPH", "Staím", "Tymotica"],
        link: "https://ra.co/events/1359285",
      },
      {
        name: "Rituals x Stützpunkt",
        date: "28.07.19",
        id: "28-07-19",
        artists: ["Tymon", "SVЛT", "Tymotica", "PR14"],
        location: "Suicide Club",
        link: "https://ra.co/events/1289789",
      },
      {
        name: "Stützpunkt",
        date: "15.05.19",
        id: "15-05-19",
        location: "://about blank",
        artists: [
          "Wrong Assessment",
          "Nastia Reigel",
          "Olivia Mendez",
          "Casual Treatment",
          "Alfandi",
        ],
        link: "https://ra.co/events/1252044",
      },
      {
        name: "Stützpunkt",
        date: "29.03.19",
        id: "29-03-19",
        location: "Arena Club",
        artists: ["Phara", "Alfredo Mazzilli", "OCD", "Saturator", "Chami"],
        link: "https://ra.co/events/1229697",
      },
      {
        name: "Disconnekt x Stützpunkt",
        date: "02.11.18",
        id: "02-11-18",
        location: "Arena Club",
        artists: [
          "Polar Inertia",
          "Reeko",
          "Chami",
          "Stallo",
          "Saturator",
          "Hannah Addams",
        ],
        link: "https://ra.co/events/1162537",
      },
      {
        name: "Stützpunkt",
        date: "07.09.18",
        id: "07-09-18",
        location: "Humboldthain Club",
        artists: [
          "Ben Buitendijk",
          "Hansi",
          "Bergen",
          "OPH",
          "Lilly",
          "Michael Witte",
          "Bongsy",
        ],
        link: "https://ra.co/events/1146341",
      },
      {
        name: "Stützpunkt Family Affair",
        date: "27.04.18",
        id: "27-04-18",
        location: "Arena Club",
        artists: [
          "Abayomi",
          "Chami",
          "Saturator",
          "Staím",
          "Tymotica",
          "Background Radiation",
        ],
        link: "https://ra.co/events/1093878",
      },
      {
        name: "Stützpunkt",
        date: "09.02.18",
        id: "09-02-18",
        location: "Arena Club",
        artists: ["Lewis Fautzi", "Z.I.P.P.O", "OPH", "Staím", "Chontane"],
        link: "https://ra.co/events/1055287",
      },
      {
        name: "Stützpunkt",
        date: "24.11.17",
        id: "24-11-17",
        location: "Arena Club",
        artists: [
          "Stanislav Tolkachev",
          "Mørbeck",
          "Under Black Helmet",
          "SVЛT",
          "Nostitz",
          "Karschau",
        ],
        link: "https://ra.co/events/1024413",
      },
      {
        name: "Stützpunkt",
        date: "01.09.17",
        id: "01-09-17",
        location: "Humboldthain Club",
        artists: [
          "Nthng",
          "Jeff Rushin",
          "ONNI",
          "Chami",
          "Minijob",
          "Background Radiation",
          "Kontrast",
          "Felix Tosend",
        ],
        link: "https://ra.co/events/995668",
      },
      {
        name: "Stützpunkt",
        date: "30.06.17",
        id: "30-06-17",
        location: "Humboldthain Club",
        artists: [
          "Charles Fenckler",
          "TWR72",
          "Saturator",
          "Tymotica",
          "Hansi",
          "Bergen",
          "VALÉ",
          "Nicolaj Tann",
        ],
        link: "https://ra.co/events/973427",
      },
      {
        name: "Stützpunkt Pres. Skryptöm 10th",
        date: "24.03.17",
        id: "24-03-17",
        location: "Humboldthain Club",
        artists: [
          "Electric Rescue",
          "The Driver aka Manu Le Malin",
          "Kmyle live",
          "OPH",
        ],
        location: "Arena Club",
        link: "https://ra.co/events/937218",
      },
      {
        name: "Stützpunkt",
        date: "20.01.17",
        id: "20-01-17",
        artists: ["Kyle Geiger", "Emmanuel", "Chami", "Background Radiation"],
        location: "Arena Club",
        link: "https://ra.co/events/913712",
      },
      {
        name: "Stützpunkt",
        date: "30.09.16",
        id: "30-09-16",
        artists: ["Benjamin Damage", "Randomer", "I/Y", "Staím", "Dial Tone"],
        location: "Arena Club",
        link: "https://ra.co/events/866865",
      },
      {
        name: "Stützpunkt",
        date: "15.07.16",
        id: "15-07-16",
        artists: [
          "Boston168",
          "Chami",
          "Counter Movement",
          "ReVenant",
          "Background Radiation",
          "BYCHE",
          "Minijob",
          "OPH",
        ],
        location: "Humboldthain Club",
        link: "",
      },
      {
        name: "Stützpunkt",
        date: "07.05.16",
        id: "07-05-16",
        artists: [
          "Maxime Dangles",
          "Andre Kronert",
          "Mario Berger",
          "Fathia",
          "Mali",
          "Opal",
          "Bongsy",
          "Joost",
          "Docmadeli",
          "Arif",
        ],
        location: "Humboldthain Club",
        link: "",
      },
      {
        name: "Stützpunkt",
        date: "12.03.16",
        id: "12-03-16",
        artists: [
          "Octave",
          "P.E.A.R.L.",
          "Opal",
          "Staím",
          "Chami",
          "OPH",
          "Background Radiation",
        ],
        location: "Arena Club",
        link: "https://ra.co/events/803590",
      },
      {
        name: "Background. Codes vs. Stützpunkt",
        date: "05.02.16",
        id: "05-02-16",
        artists: [
          "Yan Cook",
          "Ekserd",
          "Background Radiation",
          "Karschau",
          "Nostitz",
          "Golo",
          "Carne",
          "Caspar Bangs",
          "Saturator",
          "ALF-Melmac",
          "Soé",
          "Vittum",
        ],
        location: "Humboldthain Club",
        link: "https://ra.co/events/790746",
      },
      {
        name: "Stützpunkt",
        date: "13.11.15",
        id: "13-11-15",
        artists: [
          "MTD",
          "Orion",
          "MAXX",
          "SJ Tequilla",
          "OPH",
          "Docmadeli",
          "J.BRGK",
          "Legat",
          "Tham",
          "Saturator",
          "ALF-Melmac",
        ],
        location: "Humboldthain Club",
        link: "https://ra.co/events/764622",
      },
      {
        name: "Stützpunkt",
        date: "12.09.15",
        id: "12-09-15",
        location: "Humboldthain Club",
        artists: [
          "Keith Carnal",
          "Supertape",
          "Background Radiation",
          "Staím",
          "Opal",
          "Bongsy",
          "Coman",
          "SJ Tequilla",
          "Joost",
          "Chami",
          "Mike Pow",
          "Freddys House",
          "Saturator",
          "ALF-Melmac",
        ],
        link: "https://ra.co/events/733235",
      },
    ],
    activeEvent: "08-07-22",
  };

  get currentEvent() {
    return this.state.events.find(
      (event) => event.id === this.state.activeEvent
    );
  }
  getUniqueArtists() {
    var artists = this.state.events.reduce(
      (artists, event) => artists.concat(event.artists),
      []
    );
    artists = artists.filter(
      (artist, index) => artists.indexOf(artist) === index
    );
    return artists.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}));
  }

  render() {
    return (
      <React.Fragment>
        <div className="event-grid">
          {this.state.events.map((event) => (
            <div className="event-grid-item" key={event.id}>
              <Event event={event} />
            </div>
          ))}
        </div>
        <h1 className="artist-name" style={{ padding: "1em 0 0.5em 1em", textAlign: "center" }}>
          {this.getUniqueArtists().length } Booked Artists
        </h1>
        <div className="event-grid" style={{ padding: "0em 3em 1em 3em" }}>
          {this.getUniqueArtists().map((artist) => (
            <div className="event-grid-item" key={artist}>
              {artist}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }

  getEventList() {
    return (
      <ul className="list-group">
        {this.state.events.map((event) => (
          <li
            className="list-group-item bg-transparent text-white hover-bold p-0"
            onClick={() => {
              this.setState({ activeEvent: event.id });
            }}
            key={event.id}
          >
            {event.date + " - " + event.name}
          </li>
        ))}
      </ul>
    );
  }
}

export default Events;
