import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="container" style={{padding: "3em"}}>
        <h1>Impressum</h1>
        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          Hellberg &amp; Al-Afandi GbR
          <br />
          Liebigstr. 6<br />
          10247 Berlin<br />
        </p>
        <p>
          <strong>Website von:</strong>
          <br />
          Anton Elmiger
        </p>
        <h2>Kontakt</h2>
        <p>
          Telefon: +49 15170620127
          <br />
          E-Mail: info@stuetzpunkt-berlin.com
        </p>
        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>{" "}
      </div>
    );
  }
}

export default About;
