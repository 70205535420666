import "./App.css";
import React, { Component } from "react";
import Artists from "./pages/Artists";
import Event from "./pages/Events";
import Music from "./pages/Music";
import About from "./pages/About";
import CookieConsent from "react-cookie-consent";

class App extends Component {
  state = {
    navbarItems: ["ARTIST", "EVENTS", "RELEASES", "ABOUT"],
    activeNavbarItem: "ARTIST",
  };
  render() {
    return (
      <React.Fragment>
        <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
        {this.createNavbar()}
        {this.state.activeNavbarItem === "ARTIST" && <Artists />}
        {this.state.activeNavbarItem === "EVENTS" && <Event />}
        {this.state.activeNavbarItem === "RELEASES" && <Music />}
        {this.state.activeNavbarItem === "ABOUT" && <About />}
      </React.Fragment>
    );
  }

  createNavbar() {
    return (
      <React.Fragment>
        <div className="container justify-content-center">
          <ul className="nav justify-content-center">
            {this.state.navbarItems.map((item) => (
              <li className="nav-item nav-list" key={item}>
                <button
                  className={
                    this.state.activeNavbarItem === item
                      ? "nav-link btn btn-light bg-transparent shadow-none text-white nav-btn active"
                      : "nav-link btn btn-light bg-transparent shadow-none text-white nav-btn"
                  }
                  onClick={() => this.setState({ activeNavbarItem: item })}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
