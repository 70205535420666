import React, { Component } from "react";

class Artist extends Component {
  render() {
    return (
      <div className="event-container">
        <div>
          <img
            src={require(`../../assets/images/${this.props.artist.id}.webp`)}
            alt={this.props.artist.name}
            className="img-fluid"
            style={{
              minHeight: "150px",
              minWidth: "150px",
            }}
            loading="lazy"
            decoding="async"
          />
        </div>
        {this.getSoundcloudEmbed()}
        {this.getYouTubeEmbed()}
        <div
          style={{
            margin: "0.3em 0",
          }}
        >
          {this.getSoundcloud()}
          {this.getInstagram()}
          {this.getResidentAdvisor()}
        </div>
      </div>
    );
  }
  getInstagram() {
    if (this.props.artist.instagram) {
      return (
        <a
          href={this.props.artist.instagram}
          target="_blank"
          className="soundcloud"
        >
          <img
            src="https://static.wixstatic.com/media/44939e_ef6c9d84b43242b5bee377a4c5b87da8~mv2.png/v1/fill/w_21,h_21,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/44939e_ef6c9d84b43242b5bee377a4c5b87da8~mv2.png"
            className="img-fluid"
            alt="instagram"
            style={{
              margin: "0 0.5em 0 0.5em",
            }}
          />
          {/* instagram */}
        </a>
      );
    } else {
      return null;
    }
  }
  getSoundcloud() {
    if (this.props.artist.soundcloud) {
      return (
        // Image Icon
        <a
          href={this.props.artist.soundcloud}
          target="_blank"
          className="soundcloud"
        >
          <img
            src="https://static.wixstatic.com/media/44939e_b6d7422b5c6345afb2143afab118f26d~mv2.png/v1/fill/w_27,h_12,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/44939e_b6d7422b5c6345afb2143afab118f26d~mv2.png"
            className="img-fluid"
            alt="soundcloud"
            style={{
              margin: "0 0.5em 0 0.5em",
            }}
          />
          {/* soundcloud */}
        </a>
      );
    } else {
      return null;
    }
  }
  getResidentAdvisor() {
    if (this.props.artist.residentAdvisor) {
      return (
        <a
          href={this.props.artist.residentAdvisor}
          target="_blank"
          className="soundcloud"
        >
          <img
            src="https://static.wixstatic.com/media/31859f_2f616270b72449129c698df2fca32897~mv2.png/v1/fill/w_26,h_26,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/31859f_2f616270b72449129c698df2fca32897~mv2.png"
            className="img-fluid"
            alt="resident advisor"
            style={{
              margin: "0 0.5em 0 0.5em",
            }}
          />
          {/* ra */}
        </a>
      );
    } else {
      return null;
    }
  }
  getSoundcloudEmbed() {
    if (this.props.artist.soundcloudEmbed) {
      return (
        <div style={{ maxWidth: "400px", margin: "1em 0 0 0" }}>
          <iframe
            width="100%"
            height="166"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            src={this.props.artist.soundcloudEmbed}
          ></iframe>
        </div>
      );
    } else {
      return null;
    }
  }
  getYouTubeEmbed() {
    if (this.props.artist.youtubeEmbed) {
      return (
        <div style={{ maxWidth: "400px", margin: "1em 0 0 0" }}>
          <iframe
            width="100%"
            height="315"
            src={this.props.artist.youtubeEmbed}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Artist;
